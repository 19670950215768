






























































import {Component, Prop} from 'vue-property-decorator';

import * as Types from '../interfaces/Menu-Innovorder';
import BaseProduct from './BaseProduct.vue';

@Component
export default class ModalProduct extends BaseProduct {
  @Prop() readonly product!: Types.Product;

  backgroundImage():
    | {
        'background-image': string;
        'background-repeat': string;
        'background-size': string;
        'background-position': string;
      }
    | {
        'background-image'?: undefined;
        'background-repeat'?: undefined;
        'background-size'?: undefined;
        'background-position'?: undefined;
      } {
    return this.product.image && this.product.image.fullUrl
      ? {
          'background-image': `url(${this.product.image.fullUrl})`,
          'background-repeat': 'no-repeat',
          'background-size': 'contain',
          'background-position': 'center',
        }
      : {};
  }
}
