import {RouteConfig} from 'vue-router';

import {nameRoutes} from '@/core';

import News from './components/News.vue';

export const articleRoutes: Array<RouteConfig> = [
  {
    path: '/articles',
    name: nameRoutes.articles,
    component: News,
    meta: {title: 'Articles'},
  },
];
