import spacetime from 'spacetime';

import {AffluenceResponse} from '@/site/interfaces';

import {tzParis} from './date.tools';

export function setLabel(m: string): string {
  const date = spacetime(m).goto(tzParis);
  return `${date.format('{hour-24-pad}')}:${date.format('{minute-pad}')}`;
}

export function setLabels(affluenceResponse: AffluenceResponse[]): string[] {
  return affluenceResponse.map((response, index) => (!(index % 2) ? setLabel(response.start) : ''));
}

export function isCurrentStep(start: Date, end: Date): boolean {
  const now = spacetime.now().goto(tzParis);
  return now.isAfter(spacetime(start).goto(tzParis)) && now.isBefore(spacetime(end).goto(tzParis));
}

export function setPeople(affluenceResponse: AffluenceResponse[]): (number | null)[] {
  return affluenceResponse.map((response) =>
    isCurrentStep(new Date(response.start), new Date(response.end)) && response.people
      ? Math.round(response.people) || null
      : Math.round(response.avg) || null
  );
}

export function setPeopleIn(affluenceResponse: AffluenceResponse[]): (number | null)[] {
  return affluenceResponse.map((response) =>
    isCurrentStep(new Date(response.start), new Date(response.end)) && response.peopleIn
      ? Math.round(response.peopleIn) || null
      : Math.round(response.avgIn) || null
  );
}
