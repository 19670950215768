import axios from '@/core/tools/axios';

import * as Types from '../interfaces/Menu-Innovorder';

export default class MenuService {
  static async getMenu(menuId: string): Promise<Types.MenuCategory[]> {
    const response = (
      await axios.get<Types.InnovorderMenuResponse>(
        `https://api.innovorder.fr/menus/${menuId}/hash_map`
      )
    ).data;
    return this.getRootCategories(Object.values(response.data.categories));
  }

  static getRootCategories(allCategories: Types.InnovorderMenuCategory[]): Types.MenuCategory[] {
    return allCategories
      .filter((c) => !c.parentCategoryId && c.webAvailability)
      .sort((a, b) => (a.position < b.position ? -1 : 1))
      .map(this.mapToCategory)
      .filter((c) => c.products?.length || c.subCategories?.length);
  }

  static getSubcategories(
    categories?: Types.InnovorderMenuCategory[] | null
  ): Types.MenuCategory[] {
    return (categories ?? [])
      .filter((c) => c.webAvailability)
      .sort((a, b) => (a.position < b.position ? -1 : 1))
      .map(this.mapToCategory)
      .filter((c) => c.products?.length || c.subCategories?.length);
  }

  static mapToCategory = (c: Types.InnovorderMenuCategory): Types.MenuCategory => ({
    categoryId: c.categoryId,
    name: c.name,
    products: MenuService.getProducts(c.products),
    subCategories: MenuService.getSubcategories(c.subCategory),
  });

  static getProducts(products?: Types.InnovorderProduct[] | null): Types.Product[] {
    return (products ?? [])
      .filter((p) => p.webAvailability && !p.isFormula && p.isSitInEnabled)
      .map<Types.Product>((p) => ({
        productId: p.productId,
        name: p.name,
        description: p.description,
        additionalInformation: p.additionalInformation,
        price: p.price,
        vat: p.vat,
        image: p.image,
        consumptionFlags: p.consumptionFlags,
      }));
  }
}
