
import { Component, Vue } from 'vue-property-decorator';

import { mdiSvg } from '@/core';

import * as Types from '../interfaces/Menu-Innovorder';

@Component
export default class BaseProduct extends Vue {
  svgIcons = mdiSvg;

  updateRoute(open: boolean, product: Types.Product): void {
    if (open) {
      if (this.$route.hash) {
        this.$router.replace(`#${product.name}`);
      } else {
        this.$router.push(`#${product.name}`);
      }
    } else {
      this.$router.back();
    }
  }

  capitalizeFirstLetter(productName: string): string {
    return productName.charAt(0).toUpperCase() + productName.slice(1);
  }
}
