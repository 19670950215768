















































































import {Component, Vue, Watch} from 'vue-property-decorator';
import {Action, Getter} from 'vuex-class';

import {mdiSvg} from '@/core';

import * as Types from '../interfaces/Menu-Innovorder';

@Component
export default class FilterCategory extends Vue {
  @Action('setSelectedCategory', {namespace: 'menu'}) setSelectedCategory!: (category: {
    parentId: number;
    subId?: number;
  }) => void;

  @Getter('loadingMenu', {namespace: 'menu'}) loadingMenu!: boolean;

  @Getter('error', {namespace: 'menu'}) error!: boolean;

  @Getter('currentMenu', {namespace: 'menu'}) currentMenu!: Types.MenuCategory[];

  @Getter('selectedCategory', {namespace: 'menu'}) selectedCategory!: {
    parentId: number;
    subCategoryName: string;
    subId?: number;
  };

  svgIcons = mdiSvg;

  currentName = '';

  @Watch('$store.state.menu.currentMenu')
  initSelectedCategory(): void {
    const parentCategory = this.currentMenu.find(
      (menu) => menu.categoryId === this.selectedCategory.parentId
    );
    if (this.selectedCategory && parentCategory && parentCategory.subCategories?.length) {
      this.currentName =
        parentCategory.subCategories.filter(
          (menu) => menu.categoryId === this.selectedCategory.subId
        )[0].name || '';
    }
  }

  set currentCategory(value: {parentId: number; subCategoryName: string; subId?: number}) {
    this.currentName = value.subCategoryName;
    this.setSelectedCategory({parentId: value.parentId, subId: value.subId});
  }

  get currentCategory(): {
    parentId: number;
    subCategoryName: string;
    subId?: number;
  } {
    return this.selectedCategory;
  }

  setSubCategory(parentId: number, subCategory: Types.MenuCategory): void {
    this.currentCategory = {
      parentId,
      subId: subCategory.categoryId,
      subCategoryName: subCategory.name,
    };
  }
}
