















import {Component, Vue} from 'vue-property-decorator';
import {Getter} from 'vuex-class';

import NoData from '@/core/components/NoData.vue';

import FilterCategory from '../components/FilterCategory.vue';
import FilterMenu from '../components/FilterMenu.vue';
import ListCards from '../components/ListCards.vue';

@Component({
  components: {
    FilterCategory,
    ListCards,
    NoData,
    FilterMenu,
  },
})
export default class Menu extends Vue {
  @Getter('hasArticles', {namespace: 'article'}) hasArticles!: boolean;

  @Getter('name', {namespace: 'site'}) siteName!: string;

  @Getter('loadingMenu', {namespace: 'menu'}) loadingMenu!: boolean;

  @Getter('loading', {namespace: 'site'}) loading!: boolean;

  @Getter('hasCurrentMenu', {namespace: 'menu'}) hasCurrentMenu!: boolean;
}
