import {Menu, MenuState} from '../interfaces';
import * as Types from '../interfaces/Menu-Innovorder';

export const mutations = {
  SET_CURRENT_MENU(state: MenuState, menu: Types.MenuCategory[]): void {
    state.currentMenu = menu;
  },
  SET_ERROR(state: MenuState, error: boolean): void {
    state.error = error;
  },
  SET_LOADING_MENU(state: MenuState, loading: boolean): void {
    state.loading = loading;
  },
  SET_MENUS(state: MenuState, menus: Menu[]): void {
    state.menus = menus;
  },
  SET_SELECTED_CATEGORY(
    state: MenuState,
    category: {
      parentId: number;
      subId?: number;
    }
  ): void {
    state.selectedCategory = {
      parentId: category.parentId,
      subId: category.subId,
    };
  },
  SET_SELECTED_MENU(state: MenuState, menu: Menu | null): void {
    state.menuSelected = menu;
  },
};
