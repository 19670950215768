




























import {Component, Vue} from 'vue-property-decorator';
import {Action, Getter} from 'vuex-class';

import {mdiSvg} from '@/core';

import {Menu} from '../interfaces/Menu';

@Component
export default class FilterMenu extends Vue {
  @Action('setSelectedMenu', {namespace: 'menu'}) setSelectedMenu!: (params: {
    customerId: string;
    menu: Menu | null;
  }) => void;

  @Getter('loadingMenu', {namespace: 'menu'}) loadingMenu!: boolean;

  @Getter('error', {namespace: 'menu'}) error!: boolean;

  @Getter('menuSelected', {namespace: 'menu'}) selectedMenu!: Menu | null;

  @Getter('menus', {namespace: 'menu'}) menus!: Menu[];

  @Getter('customerId', {namespace: 'site'}) customerId!: string;

  svgIcons = mdiSvg;

  set currentMenu(value: number) {
    this.setSelectedMenu({customerId: this.customerId, menu: this.menus[value]});
  }

  get currentMenu(): number {
    return this.selectedMenu ? this.menus.indexOf(this.selectedMenu) : 0;
  }
}
