/* eslint-disable @typescript-eslint/ban-types */
import SiteService from '@/site/services/SiteService';

import {Menu} from '../interfaces';
import MenuService from '../services/MenuService';

export const actions = {
  getCurrentMenu: async ({commit}: {commit: Function}, id: string): Promise<void> => {
    commit('menu/SET_LOADING_MENU', true, {root: true});
    MenuService.getMenu(id)
      .then((response) => {
        commit('menu/SET_CURRENT_MENU', response, {root: true});
        commit(
          'menu/SET_SELECTED_CATEGORY',
          {
            parentId: response[0].categoryId,
            subId:
              response[0].subCategories && response[0].subCategories.length
                ? response[0].subCategories[0].categoryId
                : undefined,
          },
          {root: true}
        );
      })
      .catch(() => {
        commit('menu/SET_ERROR', true, {root: true});
      })
      .finally(() => commit('menu/SET_LOADING_MENU', false, {root: true}));
  },
  resetError: ({commit}: {commit: Function}): void => {
    commit('SET_ERROR', false);
  },
  setSelectedCategory: (
    {commit}: {commit: Function},
    category: {
      parentId: number;
      subId?: number;
    }
  ): void => {
    commit('SET_SELECTED_CATEGORY', category);
  },
  setSelectedMenu: (
    {commit}: {commit: Function},
    params: {customerId: string; menu: Menu}
  ): void => {
    commit('SET_SELECTED_MENU', params.menu);
    SiteService.getMenuId(params.customerId, params.menu.id).then((res) =>
      actions.getCurrentMenu({commit}, res.data.data)
    );
  },
};
