





























































import {Component, Vue, Watch} from 'vue-property-decorator';
import {Getter} from 'vuex-class';

import AppBar from '@/core/components/AppBar.vue';
import Footer from '@/core/components/Footer.vue';

import {nameRoutes} from './core';
import Banner from './core/components/Banner.vue';
import BottomBar from './core/components/BottomBar.vue';

@Component({
  components: {
    AppBar,
    Banner,
    Footer,
    BottomBar,
  },
})
export default class App extends Vue {
  @Getter('error', {namespace: 'site'}) error!: boolean;

  @Getter('name', {namespace: 'site'}) siteName!: string;

  @Getter('isOpenSelector', {namespace: 'site'}) isOpenSelector!: boolean;

  @Getter('hasCurrentMenu', {namespace: 'menu'}) hasCurrentMenu!: boolean;

  routes = nameRoutes;

  @Watch('$store.state.site.name')
  private updateTitle() {
    if (this.siteName) {
      document.title = this.siteName;
    }
  }

  @Watch('$store.state.site.error')
  private onError() {
    if (this.error && this.$route.name !== this.routes.notFound) {
      document.title = 'Page introuvable';
      this.$router.replace('/non-trouve');
    }
  }

  mounted(): void {
    document.title = 'Convivio';
  }
}
