import {MenuState} from '../interfaces';

export const state: MenuState = {
  currentMenu: [],
  loading: false,
  error: false,
  menus: [],
  menuSelected: null,
  selectedCategory: {parentId: 0},
};
