import {Menu, MenuState} from '../interfaces';
import * as Types from '../interfaces/Menu-Innovorder';

export const getters = {
  currentMenu: (state: MenuState): Types.MenuCategory[] => state.currentMenu,
  currentCategory: (state: MenuState): Types.MenuCategory | null =>
    state.currentMenu.find((c) => c.categoryId === state.selectedCategory.parentId) ?? null,
  error: (state: MenuState): boolean => state.error,
  hasCurrentMenu: (state: MenuState): boolean => !!state.currentMenu,
  loadingMenu: (state: MenuState): boolean => state.loading,
  menus: (state: MenuState): Menu[] => state.menus,
  menuSelected: (state: MenuState): Menu | null => state.menuSelected,
  selectedCategory: (
    state: MenuState
  ): {
    parentId: number;
    subId?: number;
  } => state.selectedCategory,
};
