
import {Component, Vue, Watch} from 'vue-property-decorator';
import {Action, Getter} from 'vuex-class';

import SelectorSite from '@/site/components/SelectorSite.vue';
import {Links, ServiceLinks, Site} from '@/site/interfaces';

import {mdiSvg, nameRoutes} from '../tools';

@Component({components: {SelectorSite}})
export default class BaseService extends Vue {
  @Getter('links', {namespace: 'site'}) links!: Links;

  @Getter('hasArticles', {namespace: 'article'}) hasArticles!: boolean;

  @Getter('isOpenSelector', {namespace: 'site'}) isOpenSelector!: boolean;

  @Getter('isDemo', {namespace: 'site'}) isDemo!: boolean;

  @Getter('siteList', {namespace: 'site'}) siteList!: Site[];

  @Getter('customerId', {namespace: 'site'}) customerId!: string;

  @Getter('enableAffluence', {namespace: 'site'}) enableAffluence!: boolean;

  // @Getter('isBookingActivated', {namespace: 'site'}) isBookingActivated!: boolean;

  // @Getter('enableContact', {namespace: 'site'}) enableContact!: boolean;
  @Action('updateOpenSelector', {namespace: 'site'}) updateOpenSelector!: (
    isOpen: boolean
  ) => void;

  affluenceDialog = false;

  svgIcons = mdiSvg;

  routes = nameRoutes;

  services: ServiceLinks[] = [];

  onlineServices: ServiceLinks[] = [];

  menuDescription = 'Consulter la carte du restaurant';

  newsDescription = 'Voir les actus de votre restaurant';

  accountDescription = 'Accéder à votre compte personnel';

  clickCollectDescription = 'Commande en ligne';

  roomServiceDescription = 'Room Service';

  menuIcon = this.svgIcons.silverware;

  newsIcon = this.svgIcons.newspaper;

  accountIcon = this.svgIcons.account;

  clickCollectIcon = this.svgIcons.shopping;

  roomServiceIcon = this.svgIcons.roomService;

  @Watch('$store.state.site.links')
  setLinks(): void {
    if (this.links) {
      this.onlineServices = [
        {
          title: 'Compte',
          name: this.routes.badge,
          link: this.links.badgeUrl || null,
          show: !!this.links.badgeUrl,
          description: this.accountDescription,
          icon: this.accountIcon,
        },
        {
          title: 'Click & collect',
          name: this.routes.clickAndCollect,
          link: this.links.clickAndCollectUrl || null,
          show: !!this.links.clickAndCollectUrl,
          description: this.clickCollectDescription,
          icon: this.clickCollectIcon,
        },
        {
          title: 'Room Service',
          name: this.routes.roomService,
          link: this.links.roomServiceUrl || null,
          show: !!this.links.roomServiceUrl,
          description: this.roomServiceDescription,
          icon: this.roomServiceIcon,
        },
      ].filter((service) => service.show);
    }
  }

  @Watch('$store.state.article.articles')
  showLocalService(): void {
    this.setServices();
  }

  @Watch('$store.state.site.customerId')
  showCustomerId(): void {
    this.setServices();
  }

  mounted(): void {
    this.setServices();
    this.setLinks();
  }

  setServices(): void {
    if (this.hasArticles) {
      this.services = [
        {
          title: 'Menus',
          name: this.routes.menu,
          show: this.customerId !== 'SFV',
          description: this.menuDescription,
          icon: this.menuIcon,
        },
        // {
        //   title: 'Actualités',
        //   name: this.routes.articles,
        //   show: this.hasArticles,
        //   description: this.newsDescription,
        //   icon: this.newsIcon,
        // },
        // {
        //   title: 'Réservation',
        //   name: 'booking',
        //   show: this.isBookingActivated,
        // },
        // {
        //   title: 'Contact',
        //   name: 'contact',
        //   show: this.enableContact,
        // },
      ].filter((service) => service.show);
    } else {
      this.services = [
        {
          title: 'Menus',
          name: this.routes.menu,
          show: this.customerId !== 'SFV',
          description: this.menuDescription,
          icon: this.menuIcon,
        },
        // {
        //   title: 'Réservation',
        //   name: 'booking',
        //   show: this.isBookingActivated,
        // },
        // {
        //   title: 'Contact',
        //   name: 'contact',
        //   show: this.enableContact,
        // },
      ].filter((service) => service.show);
    }
  }

  selectService(service: {name: string; params: {slug: string}}): void {
    if (this.$route.name !== service.name) this.$router.push(service);
  }

  onClickOutside(): void {
    this.updateOpenSelector(false);
  }
}
