











































































import {Component} from 'vue-property-decorator';

import SelectorSite from '@/site/components/SelectorSite.vue';

import BaseService from './BaseService.vue';

@Component({components: {SelectorSite}})
export default class BottomBar extends BaseService {
  value = this.$route.name || '';

  sheet = false;
}
