

































































































import {Component, Vue, Watch} from 'vue-property-decorator';
import {Getter} from 'vuex-class';

import * as Types from '../interfaces/Menu-Innovorder';
import ProductCard from './ProductCard.vue';

@Component({
  components: {
    ProductCard,
  },
})
export default class ListCards extends Vue {
  @Getter('loadingMenu', {namespace: 'menu'}) loadingMenu!: boolean;

  @Getter('error', {namespace: 'menu'}) error!: boolean;

  @Getter('currentCategory', {namespace: 'menu'}) currentCategory!: Types.MenuCategory | null;

  @Getter('selectedCategory', {namespace: 'menu'}) selectedCategory!: {
    parentId: number;
    subId?: number;
  };

  @Watch('$store.state.menu.selectedCategory.subId')
  scrollSubCategory(): void {
    if (this.selectedCategory) {
      setTimeout(() => {
        if (this.selectedCategory.subId) {
          const element = document.getElementById(this.selectedCategory.subId.toString());
          if (element) {
            window.scrollTo({
              top: element.offsetTop + 180,
              behavior: 'smooth',
            });
          }
        }
      }, 250);
    }
  }
}
