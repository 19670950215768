var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[(_vm.$route.name !== _vm.routes.notFound && !!_vm.siteName)?_c('AppBar',{staticClass:"AppBar"}):_vm._e(),(
      (_vm.$vuetify.breakpoint.smAndDown &&
        !!_vm.siteName &&
        _vm.$route.name !== _vm.routes.menu &&
        _vm.hasCurrentMenu) ||
      (!_vm.$vuetify.breakpoint.smAndDown && !!_vm.siteName && _vm.hasCurrentMenu)
    )?_c('Banner',{staticClass:"secondary--text"}):_vm._e(),_c('v-main',{staticClass:"background",class:_vm.$vuetify.breakpoint.smAndDown
        ? (!!_vm.siteName &&
            _vm.$route.name !== _vm.routes.home &&
            _vm.$route.name !== _vm.routes.badge &&
            _vm.$route.name !== _vm.routes.clickAndCollect &&
            _vm.$route.name !== _vm.routes.roomService) ||
          (_vm.hasCurrentMenu && _vm.$route.name === _vm.routes.menu)
          ? ''
          : 'mainMobile linkMobile'
        : (!!_vm.siteName &&
            _vm.$route.name !== _vm.routes.menu &&
            _vm.$route.name !== _vm.routes.home &&
            _vm.$route.name !== _vm.routes.menu &&
            _vm.$route.name !== _vm.routes.badge &&
            _vm.$route.name !== _vm.routes.clickAndCollect &&
            _vm.$route.name !== _vm.routes.roomService) ||
          (_vm.hasCurrentMenu && (_vm.$route.name === _vm.routes.menu || _vm.$route.name === _vm.routes.home))
        ? 'mainDesktop'
        : ''},[(_vm.$vuetify.breakpoint.smAndDown)?_c('transition',{attrs:{"name":"app","mode":"out-in"}},[_c('router-view')],1):_c('router-view')],1),(
      _vm.$vuetify.breakpoint.smAndDown &&
      _vm.$route.name !== _vm.routes.notFound &&
      _vm.$route.name !== _vm.routes.home
    )?_c('BottomBar'):_vm._e(),_c('v-overlay',{attrs:{"absolute":false,"value":_vm.isOpenSelector && _vm.$route.name !== _vm.routes.home,"color":"secondary"}}),(
      (_vm.$route.name !== _vm.routes.menu &&
        _vm.$route.name !== _vm.routes.articles &&
        _vm.$vuetify.breakpoint.smAndDown) ||
      _vm.$vuetify.breakpoint.smAndUp
    )?_c('Footer'):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }