import Vue from 'vue';
import VueRouter, {NavigationGuardNext, Route, RouteConfig} from 'vue-router';

import {articleRoutes} from '@/articles/router';
// import {bookingRoutes} from '@/bookings/router';
// import {feedbackRoutes} from '@/feedback/router';
import {menuRoutes} from '@/menu/router';

import {store} from './store';
import {formatSlug, nameRoutes} from './tools/router.tools';
import Badge from './views/Badge.vue';
import ClickAndCollect from './views/ClickAndCollect.vue';
// import Cgu from './views/Cgu.vue';
import Home from './views/Home.vue';
// import MentionsLegales from './views/MentionsLegales.vue';
import PageNotFound from './views/PageNotFound.vue';
import RoomService from './views/RoomService.vue';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/non-trouve',
    name: nameRoutes.notFound,
    component: PageNotFound,
    meta: {title: 'Page introuvable'},
  },
  {
    path: '/',
    name: nameRoutes.home,
    component: Home,
    meta: {title: 'Accueil'},
  },
  {
    path: '/compte',
    name: nameRoutes.badge,
    component: Badge,
    meta: {title: 'Compte'},
  },
  {
    path: '/emporter',
    name: nameRoutes.clickAndCollect,
    component: ClickAndCollect,
    meta: {title: 'À emporter'},
  },
  {
    path: '/roomservice',
    name: nameRoutes.roomService,
    component: RoomService,
    meta: {title: 'Room Service'},
  },
  // {
  //   path: '/MentionsLegales',
  //   name: 'legalNotice',
  //   component: MentionsLegales,
  //   meta: {title: 'Mentions Légales'},
  // },
  // {
  //   path: '/cgu',
  //   name: 'termsOfService',
  //   component: Cgu,
  //   meta: {title: 'CGU'},
  // },
  ...menuRoutes,
  ...articleRoutes,
  {
    path: '/*',
    redirect: '/non-trouve',
  },
  // ...bookingRoutes,
  // ...feedbackRoutes,
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to: Route, from: Route, next: NavigationGuardNext): void => {
  if (to.name !== 'home' && !to.hash) window.scrollTo(0, 0);

  const parts = window.location.host.split('.');
  const subdomain = parts[0] === 'www' ? parts[1] : parts[0];
  if (subdomain) {
    const formattedSlug = formatSlug(subdomain);
    if (
      formattedSlug.slug &&
      (!router.app.$store.getters['site/customerId'] ||
        router.app.$store.getters['site/slug'] !== formattedSlug.slug)
    )
      store.dispatch('site/getSite', formattedSlug.slug.toLowerCase());

    if (formattedSlug.portal && router.app.$store.getters['site/siteList'].length === 0)
      store.dispatch('site/getSiteList', formattedSlug.portal);
  }

  next();
});
export default router;
